body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #403D39;
}

.fc .fc-daygrid-day {
  background-color: #FCFFF3;
  padding: 15px !important;
  cursor: pointer;
}

.fc .fc-daygrid-day.fc-day-today {
  color: #FFFFFF;
}

.fc .fc-daygrid-more-link {
  position: initial !important;
}

.fc .fc-daygrid-day-bottom {
  position: initial;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: initial !important;
}

.fc-daygrid-dot-event:hover {
  background: transparent !important;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-more-popover {
  display: none;
}

.fc td, .fc th {
  background-clip: padding-box;
}

:root {
  --fc-today-bg-color: #3F7600;
  --fc-border-color: #9C9C9C;
  --fc-button-bg-color: transparent;
  --fc-button-hover-bg-color: transparent;
  --fc-button-text-color: #403D39;
}

